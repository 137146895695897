import { Box, Typography, useTheme, Button, TextField, IconButton, Tabs, Tab } from "@mui/material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { tokens } from "../../Theme";
import React, {useState, useEffect} from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import bg from "../../assets/Images/8.jpg";
import dp from "../../assets/Images/3.jpg";
import NavMenu from "../../pages/Global/NavMenu";
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from "@mui/icons-material/Female";
import { pink, blue } from '@mui/material/colors';
import { textAlign } from "@mui/system";
import { TwoKTwoTone } from "@mui/icons-material";




const Items = ({ke, value, size}) => {

    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        >
            <Box
            p={1}
            width={100}
            height={100}
            borderRadius='50%'
            boxShadow={"0 5px 10px 1px "+colors.myTheme[5]}
            display='flex'
            justifyContent='center'
        alignItems='center'
            >
                <Typography variant={size} color='secondary'>{ke}</Typography>
            </Box>
            <Typography sx={{
                // marginLeft:"2rem",
                // marginTop:"-5px"
            }} variant="h6" >{value}</Typography>
        </Box>
    );

}

const Profile = ({
    one,
    two,
    thr,
    oneVal,
    twoVal,
    thrVal,
    data
}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { name, gender, user_id, profile_pic, dob, weight, height } = data;

    return (
        <Box
        height='60vh'
        // width='100vw'
        display="flex"
        flexDirection='column'
        alignItems='center'
        // p={2}
        sx={{
            background : `${colors.myTheme[4]} !important`,
            // backgroundSize: "cover",
            position:"relative"
        }}
        >
        {/* <NavMenu /> */}
        <Box
        sx={{
            height:'40vh',
            background : `url(${bg}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "10%",
            // position:"absolute",
            // top:'0',
            // left:'0',
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'

            // zIndex:'+1'

        }}>
            
            {/* <Box
            sx={{
                padding:"1rem 2rem",
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                borderRadius:'25px',
                marginTop:'10%'
            }}
            >
                <Typography variant="h1">Varun Modi</Typography>
            </Box> */}
        </Box>
        <Box display="flex" justifyContent='space-between' gap="2rem" width="90%" p={2}>
            <Box display='flex' position='relative'>
                {profile_pic ? <Avatar
                    // alt="Remy Sharp"
                    src={`https://vinecork.com/sugarX/api/${profile_pic}`}
                    sx={{ width: 200, 
                        height: 200, 
                        boxShadow:"0 5px 20px 1px "+colors.myTheme[5],
                        position:'absolute',
                        top:"-100px",
                }}
                /> : <Avatar
                // alt="Remy Sharp"
                src={dp}
                sx={{ width: 200, 
                    height: 200, 
                    boxShadow:"0 5px 20px 1px "+colors.myTheme[5],
                    position:'absolute',
                    top:"-100px",
            }}
            />}
                <Box
                    display='flex'
                    flexDirection='column'
                    marginLeft='250px'
                    gap='1rem'>
                            <Box
                            display='flex'
                            gap='1rem'
                            alignItems='centre'>
                                <Typography variant="h1">{name}</Typography>
                                {
                                    gender === "Female"
                                    ? <FemaleIcon  sx={{
                                    color:pink[500]
                                    }}/>
                                    : <MaleIcon sx={{
                                    color:blue[500]
                                    }}/>
                                }
                                
                            </Box>
    
                            <Box
                            display='flex'
                            gap='1rem'
                            alignItems='centre'
                            
                            >
                                <Typography color='info' variant="h4">PATIENT</Typography>
                            </Box>
                    </Box>
            </Box>
                <Box display='flex' alignItems='center' gap='3rem' justifyContent='space-between'>
                        <Box display='flex' gap='0.5rem' flexDirection='column' alignItems='center'>
                            <Typography variant="h5">{one}</Typography>
                            <Typography sx={{
                                height:'80px',
                                width:'80px',
                                borderRadius:'50%',
                                border:'2px solid ' + colors.blueAccent[500],
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center'
                            }} variant="h1">{oneVal}</Typography>
                        </Box>
                        <Box display='flex' gap='0.5rem' flexDirection='column' alignItems='center'>
                            <Typography variant="h5">{two}</Typography>
                            <Typography sx={{
                                height:'80px',
                                width:'80px',
                                borderRadius:'50%',
                                border:'2px solid '+ colors.greenAccent[500],
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center'
                            }} variant="h1">{twoVal}</Typography>
                        </Box>
                        <Box display='flex' gap='0.5rem' flexDirection='column' alignItems='center'>
                            <Typography variant="h5">{thr}</Typography>
                            <Typography sx={{
                                height:'80px',
                                width:'80px',
                                borderRadius:'50%',
                                border:'2px solid '+ colors.redAccent[500],
                                display:'flex',
                                justifyContent:'center',
                                alignItems:'center'
                            }} variant="h1">{thrVal}</Typography>
                        </Box>
                    </Box>
            </Box>
        </Box>
    );
}

export default Profile;
