import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import React, {useEffect, useState} from "react";
import { pink, blue } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { tokens } from "../Theme";
import Avatar from '@mui/material/Avatar';
import { mockPatient } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import FemaleIcon from '@mui/icons-material/Female';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import MaleIcon from '@mui/icons-material/Male';
import Header from "../components/Headline";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

const Doctors = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [permisions, setpermisions] = useState([]);

  useEffect(() => {
    setpermisions(JSON.parse(sessionStorage.getItem('permission')));
  }, [sessionStorage.getItem('permission')]);



  const [AllDoctors, setAllDoctors] = useState([]);

  const handleDelete = () => {

  }

  const openDetails = (user_id) => {
    navigate('/doctors/report', {state :
      {user_id : user_id}
    })
  }

  useEffect(() => {
    var config = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/get-all-doctors/',
      headers: { }
    };
    
    axios(config).then(function (response) {
      setAllDoctors(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  }, []);

  

  const columns = [
    { field: "id",
     headerName: "Doctor Id",
    //  width:100
    }, {
        field: "profile_pic",
        headerName: "Profile",
        // flex: 1,
        renderCell: ({ row: { profile_pic } }) => {
          return (
            <Avatar alt={profile_pic}                     
            src={profile_pic ? `https://vinecork.com/sugarX/api/${profile_pic}` : null}
            sx={{ width: 36, height: 36 }}/>
          );
        },
      },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width:150,
    },
    {
      field: "sername",
      headerName: "Last Name",
      width:150,
    },
    {
      field: "speciality",
      headerName: "Speciality",
      width:150,
    },
    

    {
      field: "phone",
      headerName: "Phone",
      headerAlign: "left",
      align: "left",
      flex:1,
      minWidth: 150
    },
    
    
    
    {
        field: "info",
        // type: 'actions',
        headerName: "Details",
        renderCell: ({row}) => {
          return (
            <Button
              variant="outlined"
              color="info"
              // onClick={(event) => {
              //   openDetails(event, user_id);
              // }}
            >
              <Link to={`/doctor/${row.doctor_id}/${row.id}`} style={{
                textDecoration:'none',
                color:'#0288d1'
              }}>Report</Link>
            </Button>
            // <Link to='/patient/report'>Details</Link>
          );
        }
      },

      {
        field: "delete",
        type: 'actions',
        headerName: "Delete",
        // align:"right",
        // flex:1,
        
        renderCell: (cellValues) => {
          return (
            <Button
              variant="outlined"
              color="error"
              onClick={(event) => {
                handleDelete(event, cellValues);
              }}
              disabled={!permisions?.['Delete Doctor']}

            >
              Delete
            </Button>
          );
        }
      },
  ];

  return (
    <Box m="20px">
      <Header title="Doctor" subtitle="An apple a day keeps doctor away" />
      <Box
        // m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "1px solid black",
            fontSize: "14px"
          },
          "& .name-column--cell": {
            color: colors.myTheme[3],
            
          },
          "& .MuiDataGrid-columnHeaders": {
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)",
            // border: "2px solid blue",
            fontSize: "16px"
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.myTheme[4],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
        rows={ AllDoctors } 
        columns={columns} 
        hideFooter={true}
        hideFooterPagination={true}
        />
      </Box>
    </Box>
  );
};

export default Doctors;