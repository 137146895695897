import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import React, {useEffect, useState} from "react";
import { pink, blue } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { tokens } from "../../Theme";
import Avatar from '@mui/material/Avatar';
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import FemaleIcon from '@mui/icons-material/Female';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import MaleIcon from '@mui/icons-material/Male';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

const CriticalTable = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [permisions, setpermisions] = useState([]);

  useEffect(() => {
    setpermisions(JSON.parse(sessionStorage.getItem('permission')));
  }, [sessionStorage.getItem('permission')]);



  const [AllPatients, setAllPatients] = useState([]);

  const handleDelete = () => {

  }

  const openDetails = (user_id) => {
    navigate('/patient/report', {state :
      {user_id : user_id}
    })
  }

  useEffect(() => {
    var config = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/get-all-critical/',
      headers: { },
      data:{
        'month':4
      }
    };
    
    axios(config).then(function (response) {
        console.log(response.data.blood_presure)
        const datalist = []
        const mergelist = [...response?.data?.blood_presure, ...response?.data?.sugar_level]
        mergelist.forEach((dic) => {
            const disi = {
                "id" : dic.id,
                "name": dic.user_id.name,
                "phone": dic.user_id.phone,
                "type_of_dibeties":dic.user_id.type_of_dibeties,
                "gender":dic.user_id.gender,
                "pre":dic.pre,
                "post":dic.post,
                "long_acting_insulin":dic.long_acting_insulin,
                "short_acting_insulin":dic.short_acting_insulin,
                "user_id":dic.user_id.user_id
            }
            datalist.push(disi);
        })
        // console.log(datalist)

        setAllPatients(datalist)
    })
    .catch(function (error) {
      console.log(error);
    });
  }, []);

//   function getRowId(row) {
//     return row.name; // Here, we are using the name property as the unique identifier for each row
//   }
  

  const columns = [
    // { field: "id",
    //  headerName: "S.No.",
    // //  valueGetter: (params) => params.row.id + 1 
    // //  width:100
    // }, 
    {
        field: "profile_pic",
        headerName: "Profile",
        // flex: 1,
        renderCell: ({ row: { profile_pic } }) => {
          return (
            <Avatar alt={profile_pic}                     
            src={profile_pic ? `https://vinecork.com/sugarX/api/${profile_pic}` : null}
            sx={{ width: 36, height: 36 }}/>
          );
        },
      },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      minWidth: 160
    },
    
    {
        field: "gender",
        headerName: "Gender",
        headerAlign: "left",
        align: "center",
        hide: false,
        renderCell: ({row: { gender }}) => {
          return (
            <Box>
              {
                gender === "Female"
                ? <FemaleIcon sx={{
                  color:pink[500]
                }}/>
                : gender === "Male"
                ? <MaleIcon sx={{
                  color:blue[500]
                }}/>
                : <NoAccountsIcon />
              }

            </Box>
          );
        }
      },

    
      
    
    {
        field: "type_of_dibeties",
        headerName: "Dibeties",
        headerAlign: "left",
        align: "left",
        hide: false,
      },
    {
      field: "pre",
      headerName: "Pre",
      headerAlign: "left",
      align: "left",
      hide: false
    },

    {
      field: "post",
      headerName: "Post",
      headerAlign: "left",
      align: "left",
      hide: false
    },

    
    
    
    {
        field: "info",
        // type: 'actions',
        headerName: "Details",
        hide: false,
        renderCell: ({row}) => {
          return (
            <Button
              variant="outlined"
              color="info"
              // onClick={(event) => {
              //   openDetails(event, user_id);
              // }}
            >
              <Link to={`/patient/${row.id}/${row.user_id}`} style={{
                textDecoration:'none',
                color:'#0288d1'
              }}>Report</Link>
            </Button>
            // <Link to='/patient/report'>Details</Link>
          );
        }
      }
  ];

  return (
    <Box m="20px">
      <Box
        // m="40px 0 0 0"
        height="50vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "1px solid black",
            fontSize: "14px"
          },
          "& .name-column--cell": {
            color: colors.myTheme[3],
            
          },
          "& .MuiDataGrid-columnHeaders": {
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)",
            // border: "2px solid blue",
            fontSize: "16px"
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.myTheme[4],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={ AllPatients } columns={columns} />
      </Box>
    </Box>
  );
};

export default CriticalTable;