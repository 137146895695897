import React, {useState, useEffect, useRef, useContext} from 'react';
import { Box, Typography, colors, useTheme } from "@mui/material";
import { ColorModeContext, tokens } from "../../Theme";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import AuthContext from './AuthProvider';
import useAuth from './useAuth';
import ParticleBackground from '../../assets/Background/ParticleBackground';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik } from "formik";
import * as yup from "yup";
import bg from "../../assets/Images/8.jpg";

const Login = () => {


const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required"),
  password: yup.string().required("required")
});

const initialValues = {
  email: "",
  password:""
};


    const {auth, setAuth } = useAuth();
    // const [email, setEmail] = useState("");
    // const [password, setPass] = useState("");
    const colorMode = useContext(ColorModeContext);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const getRole = async( roleid ) => {
      

      var config = {
        method: 'post',
        url: "https://vinecork.com/sugarX/api/get-roles/",
        headers: {},
      };
      
      await axios(config)
      .then(function (response) {
        console.log(roleid)
        console.log(response.data.data.filter((da) => da.id === roleid.data.role))
        sessionStorage.setItem("permission", JSON.stringify(response?.data?.data.filter((da) => da.id === roleid.data.role)[0].permisions))
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const handleFormSubmit = async (values) => {
        // e.preventDefault();
        // console.log(values)

        const data = {
          email:values.email,
          password:values.password
      }

        var config = {
          method: 'post',
          url: "https://vinecork.com/sugarX/api/admin-login/",
          headers: {},
          data : data
        };
        
        await axios(config)
        .then(function (response) {
          const accessToken = response?.data?.accessToken;
          const roles = response?.data?.roles;
          // setAuth({values.email, values.password, roles, accessToken})

          sessionStorage.setItem("user", JSON.stringify(response?.data));
          getRole(response?.data)
          toast.success(
            'Login Successful',
            {
              position: toast.POSITION.TOP_RIGHT
          }
          );
          setTimeout(() => {
            navigate(from, {replace: true}, );
          }, 2000);
          colorMode.toggleColorMode();
          // console.log(from)
          // console.log(JSON.stringify(response?.data));
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.msg,
            {
              position: toast.POSITION.TOP_RIGHT
          }
          );
          console.log(error);
        });
    }

    return (
        <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        >
        <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          // <img src={}></img>


          <form onSubmit={handleSubmit} style={{
            width: 450,
            // height: 300,
            background:'rgba(255, 255, 255, 0.8)',
            borderRadius:'15px',
            boxShadow:"0 0 10px 3px #000000",
            zIndex: 99,
            padding: "2rem",
            // border: "2px solid #000000"
          }}>
              <Grid
                container
                spacing={4}
                justifyContent="center"
              >
                <Grid item >
                  <Typography variant='h2' color="info">
                    LOGIN
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant='h4' color="info">
                    EMAIL
                  </Typography>
                  <TextField
                    fullWidth
                    margin="dense"
                    color='info'
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderWidth: "3px", // Change the border width here
                      borderRadius:'25px'

                        }
                      }
                    }}
                    variant="outlined"
                    label="Email"
                    id="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    name='email'
                    // focused 
                    // onChange={(e) => {setEmail(e.target.value)}}
                  />
                </Grid>
                <Grid item xs={12}>
                  
                <Typography variant='h4' color="info">
                    PASSWORD
                  </Typography>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    color="info"
                    label="Password"
                    type="password"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderWidth: "3px", // Change the border width here
                      borderRadius:'25px'

                        }
                      }
                    }}
                    id="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={!!touched.password && !!errors.password}
                    helperText={touched.password && errors.password}
                    // onChange={(e) => {setPass(e.target.value)}}
                    // focused 
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    sx={{
                      borderWidth: "3px", // Change the border width here
                      height:"45px",
                      fontSize:'16px',
                      borderRadius:'25px'
                    }}
                    margin="dense"
                    color="info" 
                    variant="outlined"
                    type='submit'
                    >Login</Button>
                </Grid>
                
            </Grid>
          </form>
          )}
        </Formik>

        <ParticleBackground />
        <ToastContainer />

        </Box>

    );
}

export default Login;
