import React, {useState, useEffect} from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import Grid from '@mui/material/Grid';
import { tokens } from "../../Theme";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from "@mui/material/Divider";
import { withStyles, MenuItem } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';


const OperatorModal = (props) => {


      const theme = useTheme();
      const colors = tokens(theme.palette.mode);
    
      const [roleValue, setroleValue] = useState("");
      // const [AllRole, setAllRole] = useState({});

      const [operatorDetail, setOperatorDetail] = useState({
        id:"",
        first_name:"",
        last_name:"",
        email:"",
        contact:"",
        username:"",
        password:"",
        role:"",
      })

      const setRolesOps = () => {
        var myrole = [...props.AllRole].find(roles => roles.id ===  props.data.role).role;
        setroleValue(myrole);
        setOperatorDetail({
          id:props.data.id,
          first_name: props.data.first_name,
          last_name: props.data.last_name,
          email: props.data.email,
          contact: props.data.contact,
          username: props.data.username,
          password: props.data.password,
          role: props.data.role,
        });
      }

      useEffect(() => {

        { props.isdata && setRolesOps(); }
        if(!props.isdata){             
        setOperatorDetail({
            id:"",
            first_name:"",
            last_name:"",
            email:"",
            contact:"",
            username:"",
            password:"",
            role:"",
          });
          setroleValue("");
        }

    }, [props.isopen]);
  
      const handleOperatorDetail = (event) => {
         setOperatorDetail({
          ...operatorDetail,
          [event.target.name]: event.target.value,
        })
        
      };    

      
    const handleClose = () => {
        props.set();
    };


  const handleAddOperator = async () =>{
    var config = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/create-operator/',
      headers: { },
      data : operatorDetail,
    };
    
    await axios(config).then(function (response) {
      toast.success(
        response?.data?.msg,
        {
          position: toast.POSITION.TOP_RIGHT
      }
      );
      props.set();
    })
    .catch(function (error) {
      toast.error(
        error.response.data.msg.email,
        {
          position: toast.POSITION.TOP_RIGHT
      }
      );
      console.log(error);
    });
}

const handleEditOperator = async () =>{
  var config = {
    method: 'post',
    url: 'https://vinecork.com/sugarX/api/update-operator/',
    headers: { },
    data : operatorDetail,
  };
  
  await axios(config).then(function (response) {
    
    toast.success(
      response?.data?.msg,
      {
        position: toast.POSITION.TOP_RIGHT
    }
    );
    console.log(response)
    props.set();
  })
  .catch(function (error) {
    toast.error(
      error.response.data.msg.email[0],
      {
        position: toast.POSITION.TOP_RIGHT
    }
    );
    console.log(error);
  });
}


    return ( 
        <Dialog
            fullWidth
            maxWidth="sm"
            open={props.isopen}
          >
            <DialogContent         
            sx={{backgroundColor:colors.myTheme[1]}}>
              <Grid container >
                {/* <Grid item xs={8}> */}
                  <Grid container direction="row" >
                    <Grid item xs={8}>
                      <Typography color={colors.myTheme[6]}  variant="h5">
                        Add Operator
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    // direction="row"
                    spacing={1}
                  >
                    
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    color="info"
                    variant="outlined"
                    label="Username"
                    value={operatorDetail.username}
                    id="username"
                    name="username"
                    required
                    onChange={handleOperatorDetail}
                  />
                </Grid>
                
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    color="info"
                    label="Password"
                    value={operatorDetail.password}
                    id="password"
                    name="password"
                    required
                    onChange={handleOperatorDetail}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    color="info"
                    label="First Name"
                    value={operatorDetail.first_name}
                    id="first_name"
                    name="first_name"
                    onChange={handleOperatorDetail}

                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    color="info"
                    value={operatorDetail.last_name}
                    label="Last Name"
                    id="last_name"
                    name="last_name"
                    onChange={handleOperatorDetail}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    color="info"
                    label="Contact"
                    value={operatorDetail.contact}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    id="contact"
                    name="contact"
                    onChange={handleOperatorDetail}
                  />
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    color="info"
                    label="Email"
                    value={operatorDetail.email}
                    required
                    id="email"
                    name="email"
                    onChange={handleOperatorDetail}
                  />
                </Grid>
    
                    <Grid item xs={12}>
                      <TextField
                        style={{ marginTop: 20, color: "red" }}
                        label="Role"
                        fullWidth
                        select
                        variant="outlined"
                        color="info"
                        value={roleValue}
                        margin="dense"
                        name='role'
                        // onChange={handleOperatorDetail}
                        helperText="Please select operator role"
                      >
                        {Object.values(props.AllRole).map(option => (
                          <MenuItem onClick={() => {
                            setroleValue(option.role);
                            setOperatorDetail({
                              ...operatorDetail,
                              ['role']: option.id,
                            })
                          }} key={option.id} value={option.role}>
                            {option.role}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                </Grid>
            </Grid>
            </DialogContent>
            
            <DialogActions sx={{backgroundColor:colors.myTheme[1]}}>
                {
                    props.isdata 
                    ? <Button variant="outlined" color="info" onClick={handleEditOperator}>Edit</Button>
                    : <Button variant="outlined" color="info" onClick={handleAddOperator}>Add</Button>


                }
                <Button variant="outlined" color="error" onClick={handleClose}>Cancel</Button>
            </DialogActions>
            <ToastContainer />
        </Dialog>
    
        );
}

export default OperatorModal;
