import React, {useState} from 'react'
import Sidebar from './SideNav';
import Navbar from './Navbar';


const PageBody = (props) => {

    const [isSidebar, setIsSidebar] = useState(true);

    return(
    <div className="app">
        <Sidebar isSidebar={isSidebar} />
        <main className="content">
            <Navbar setIsSidebar={setIsSidebar} />
            <div>{props.children}</div>
        </main>
    </div>
    )
}

export default PageBody