import React, {useState, useEffect} from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import Grid from '@mui/material/Grid';
import { tokens } from "../../Theme";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from "@mui/material/Divider";
import { withStyles, MenuItem } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

const DeleteRoles = (props) => {

    
    const handleClose = () => {
        props.set();

    };

    const handleDelete = async (delete_id) => {


        const Deletedata = {
            'id' : delete_id
        }

        var config = {
            method: 'post',
            url: 'https://vinecork.com/sugarX/api/delete-role/',
            headers: { },
            data : Deletedata
          };
          
          await axios(config)
          .then(function (response) {
            toast.success(
                response?.data?.msg,
                {
                  position: toast.POSITION.TOP_RIGHT
              }
              );
              props.set();
          })
          .catch(function (error) {
            toast.error(
                error.response.data.msg,
                {
                  position: toast.POSITION.TOP_RIGHT
              }
              );
          });

    }

    return (
        <Dialog
        open={props.isopen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete Roles"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete !! <br/>
            Role  -   {props.data.role} <br/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='outlined' color='error'>Cancel</Button>
          <Button onClick={() => handleDelete(props.data.id)} variant='outlined' color='success' autoFocus>Confirm</Button>
        </DialogActions>
        <ToastContainer />
      </Dialog>
    );
}

export default DeleteRoles;
