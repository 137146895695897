import React, {useState} from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Box, Typography, useTheme } from "@mui/material";
import Header from "../components/Headline";
import { tokens } from "../Theme";
import "../styles/content-styles.css";




const Terms = () => {
    
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [bodyData, setBodyData] = useState("");


  const handleBody=(e, editor)=>{
    setBodyData(editor.getData());
}

    return (
        <Box m="20px">
            <Header title="Terms" subtitle="Terms & Condition" />
            <CKEditor 
                editor={ ClassicEditor } 
                data={bodyData}                        
                onChange={ ( event, editor ) => {
                    handleBody(event, editor);
                } }/>
        </Box>
    );
}

export default Terms;
