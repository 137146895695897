import React, { useEffect, useRef, useState } from 'react'
import { DateRangePicker } from 'react-date-range'

import { addDays } from 'date-fns'
import { Box, Typography, useTheme, Button, TextField, IconButton, Tabs, Tab } from "@mui/material";
import axios from 'axios';
import { tokens } from "../../Theme";
import  ApexChart  from '../Charts/SugarLevelGraph';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import { Calendar } from 'react-date-range';
import { format } from 'date-fns';
import Line from '../Charts/BloodPressureLine';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../../assets/styles/dateRange.css';
import SugarLevelGraph from '../Charts/SugarLevelGraph';


const SugerLevel = ({
  setone,
  settwo,
  setthr,
  setoneVal,
  settwoVal,
  setthrVal,
  user_id
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const [value, setValue] = React.useState<DateRange<Date>>([null, null]);
      const [value, setValue] = React.useState([]);
      const [slData, setslData] = useState({});
      const [time, settime] = useState('morning');

    const handleChange = (event) => {
        settime(event.target.value)
      }

      const [range, setRange] = useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: 'selection'
        }
      ])

    const [calender, setcalender] = useState('');

    const [open, setOpen] = useState(false)

  // get the target element to toggle 
  const refOne = useRef(null)

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])

  const hideOnEscape = (e) => {
    // console.log(e.key)
    if( e.key === "Escape" ) {
      setOpen(false)
    }
  }

  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    // console.log(e.target)
    if( refOne.current && !refOne.current.contains(e.target) ) {
      setOpen(false)
    }
  }


    const getSugerLevel = async () =>  {
          var config = {
          method: 'post',
          url: 'https://vinecork.com/sugarX/api/sugar-level-report/',
          headers: { },
          data : {
            "user_id":user_id,
            "start_date":format(range[0].startDate, 'yyyy-MM-dd'),
            "end_date":format(range[0].endDate, 'yyyy-MM-dd'),
            "time":time
            }
          };
        
        await axios(config).then(function (response) {
            console.log(JSON.stringify(response.data), 'dddddddddd');
            setslData(response?.data?.data);
            // setBloodPressure(response.data.data);
          }).catch(function (error) {
            console.log(error);
          });
        }

        // console.log(bpData, 'assasas');
        // console.log(range)
        useEffect(() => {
            getSugerLevel();
        }, [time, range]);

    return (
        <Box
        display='flex'
        flexDirection='column'
        gap='1rem'
        >
            <Box
            display='flex'
            alignItems='center'
            gap='1rem'
            position='relative'
            >
            <Box>            
            <TextField
                value={`${format(range[0].startDate, "yyyy-MM-dd")} -- ${format(range[0].endDate, "yyyy-MM-dd")}`}
                readOnly
                label='Date'
                onClick={ () => setOpen(open => !open) }
              />

            <div ref={refOne}>
              {open && 
                <DateRangePicker
                  onChange={item => setRange([item.selection])}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={range}
                  months={2}
                  direction="horizontal"
                  className="calendarElement"
                />
              }
            </div>
            </Box>
            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Time</InputLabel>
                    <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={time}
                    label="Time"
                    onChange={handleChange}
                    >
                    <MenuItem value='morning'>morning</MenuItem>
                    <MenuItem value='evening'>evening</MenuItem>
                    <MenuItem value="afternoon">afternoon</MenuItem>
                    </Select>
                </FormControl>
                </Box>
            </Box>
            
            {/* <ApexChart data={bpData}/> */}
            {/* { bpData &&  <Line data={bpData && bpData.all_data}/>} */}
            {slData &&  <SugarLevelGraph data={slData && slData.all_data}/>}
        </Box>
    );
}

export default SugerLevel;
