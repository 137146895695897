import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../Theme";
import React from "react";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  
  return (
    <Box display="flex" justifyContent="space-between">
        <Box mb="30px">
        <Typography
            variant="h2"
            color={colors.grey[100]}
            fontWeight="bold"
            sx={{ m: "0 0 5px 0" }}
        >
            {title}
        </Typography>
        <Typography variant="h5" color={colors.myTheme[6]}>
            {subtitle}
        </Typography>
        </Box>
    </Box>
  );
};

export default Header;