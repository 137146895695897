import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Box, Typography, useTheme, Button, TextField, IconButton, Tabs, Tab } from "@mui/material";
import Profile from '../Profile/profile';
import { useLocation, useParams } from 'react-router-dom';
import Header from '../Headline';
import { pink, blue } from '@mui/material/colors';
import DoctorProfile from '../Profile/doctorProfile';
import CriticalPatient from './CriticalPatient';



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  
    
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const DoctorDetails = () => {

    const { did, uid } = useParams();
    // console.log(pid, 'aaaaaaaaa')
    const [value, setValue] = React.useState(0);
  const [AllPatients, setAllPatients] = useState([]);


    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    

    const location = useLocation()
    // const  user_id  = location.state.patientDetail.id;
    const [personalDetail, setPersonalDetail] = useState({});
    const [criticalcount, setcriticalcount] = useState(0);



    const getPersonalDetails = (did) => {

      var config = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/get-doctor-details/',
      headers: { },
      data : {'id' : did}
      };

   axios(config).then(function (response) {
        // console.log(JSON.stringify(response.data));
        setPersonalDetail(response.data.data);
      }).catch(function (error) {
        console.log(error);
      });
  }

  const getCriticalPatient = (uid) => {
    
    var config = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api//get-critical-patients/',
      data:{
        "id":uid
      },
      headers: { }
    };
    
    axios(config).then(function (response) {
      setAllPatients(response?.data?.data?.['user data']);
      setcriticalcount(response?.data?.data?.count)
      console.log(response)
    })
    .catch(function (error) {
      console.log(error);
    });
  }


useEffect(() => {
  if(did){
      getPersonalDetails(did);
      getCriticalPatient(uid);
  }
}, []);


    return (
        <Box 
        m="20px"
        maxHeight="85vh"
        overflow="auto"
        >
            <Header title={"Doctor Detail"} subtitle={"Details of the Doctors"}/>
            <DoctorProfile data={personalDetail} criticalcount={criticalcount}/>
            <Box m={'20px'} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs 
                    value={value} 
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile>
                    <Tab label="Personal Details" {...a11yProps(0)} />
                    <Tab label="Friends" {...a11yProps(1)} />
                    <Tab label="Critical Patients" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                <Typography>h2so4</Typography>

                </TabPanel>
                <TabPanel value={value} index={1}>
                <Typography>h2so4</Typography>

                </TabPanel>
                <TabPanel value={value} index={2}>
                    <CriticalPatient AllPatients={AllPatients}  uid={uid} />
                </TabPanel>
                </Box>
        </Box>
    );
}

export default DoctorDetails;
