import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import { tokens } from "../Theme";
import { mockOperator } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import Switch from '@mui/material/Switch';
import Header from "../components/Headline";
import React, {useState, useEffect} from "react";
import axios from "axios";
import OperatorModal from "../components/Operator/OperatorModal";
import DeleteOperator from "../components/Operator/DeleteOperator";
import { ToastContainer, toast } from 'react-toastify';
import TagsModal from "../components/ChatTags/TagsModal";


const ChatTags = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = React.useState(false);
  const [ deleteopen, setDeleteOpen] = useState(false);
  const [isdata, setIsData] = useState(false);
  const [Trigger, setTrigger] = useState("");
  const [AllTags, setAllTags] = useState({});
  const [TagsDetail, setTagsDetail] = useState({});
  const [permisions, setpermisions] = useState([]);

  useEffect(() => {
    setpermisions(JSON.parse(sessionStorage.getItem('permission')));
  }, [sessionStorage.getItem('permission')]);


  const handleDelete = (event, cellValues) => {

  }
  const handleEdit = (event, cellValues) => {
    setOpen(true);
    setIsData(true);
    setTagsDetail(cellValues.row);

  }
  const handleAdd = (event, cellValues) => {
    setOpen(true);
    setIsData(false);
  }



  const handleChange = async (event, row) => {

    const data = {
        id : row.id,
        tag_name : row.tag_name,
        status: !row.status
        }

    var config = {
        method: 'post',
        url: 'https://vinecork.com/sugarX/api/update-tags/',
        headers: { },
        data : data
      };
      await axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setTrigger(response.data);
        // setAllTags(response.data.data)
      })
      .catch(function (error) {
        console.log(error.response.data.data);
        // setAllTags(error.response.data.data);
      });
  }



  const fetchTags = async () => {
    var config = {
      method: 'get',
      url: 'https://vinecork.com/sugarX/api/get-all-tags/',
      headers: { }
    };
    await axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      setAllTags(response.data.data)
    })
    .catch(function (error) {
      // console.log(error.response.data.data);
      setAllTags(error.response.data.data);
    });
  }


  useEffect(() => {
    let mounted = true;
    if(mounted){
      fetchTags();
    }
    return () => {
      mounted = false;
    };
  }, [open, deleteopen, Trigger]);



  const columns = [
    { 
      field: "id",
     headerName: "ID",
    //  width:100
    },
    {
      field: "tag_name",
      headerName: "Tags",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    
      {
        field: "status",
        headerName: "Status",
        // flex:1,
        renderCell: ({row}) => {
          return (
            // <Button
            //   variant="outlined"
            //   color="info"
            //   onClick={(event) => {
            //     // handleEdit(event, cellValues);
            //     handleOperatorEdit(event, cellValues);
            //   }}
            // >
            //   Edit
            // </Button>
            <Switch
                checked={row.status}
                onClick={(event) => {
                    handleChange(event, row)
                      }}
                inputProps={{ 'aria-label': 'controlled' }}
                color='secondary'
                />
          );
        }
      },

      {
        field: "edit",
        headerName: "Edit",
        // flex:1,
        
        renderCell: (cellValues) => {
          return (
            <Button
              variant="outlined"
              color="info"
              onClick={(event) => {
                handleEdit(event, cellValues);
              }}
              disabled={!permisions?.['Edit Tags']}

            >
              Edit
            </Button>
          );
        }
      },

      {
        field: "delete",
        headerName: "Delete",
        // flex:1,
        
        renderCell: (cellValues) => {
          return (
            <Button
              variant="outlined"
              color="error"
              onClick={(event) => {
                handleDelete(event, cellValues);
              }}
              disabled={!permisions?.['Delete Tags']}
            >
              Delete
            </Button>
          );
        }
      }
  ];

  return (
    <Box m="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
      <Header title="ChatTags" subtitle="Predefined tags created for the users" />
          <Button
              sx={{
                display:"flex",
                gap:"1rem",
                justifyContent:"space-between",
                borderRadius:"25px",
                border:"2px solid " + colors.myTheme[6],
              }} 
              onClick={handleAdd}
              disabled={!permisions?.['Create Tags']}
              variant="outlined" color="secondary">
                <PersonAddRoundedIcon fontSize="large" sx={{color: colors.myTheme[5],
                borderRadius: "25px",
                }}  />
                <Typography variant="h5" color={colors.myTheme[5]}>
                    TAGS
                </Typography>
          </Button>
          {/* <AddOperator open={open} set={() => handlePatientAdd(false)}/> */}
      </Box>
    
      {/* <EditOperator open={Editopen} set={() => handleEditClose()} data={EditDetails}/> */}
      {/* <OperatorModal isopen={open} set={() => setOpen(!open)} data={operatorDetail} isdata={isdata} AllRole={AllRole}/> */}
      <TagsModal isopen={open} set={() => setOpen(!open)} data={TagsDetail} isdata={isdata} />
      {/* <DeleteOperator isopen={deleteopen} set={() => setDeleteOpen(!deleteopen)} data={operatorDetail} /> */}
      <Box
        // m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "1px solid black",
            fontSize: "14px"
          },
          "& .name-column--cell": {
            color: colors.myTheme[3],
          },
          "& .MuiDataGrid-columnHeaders": {
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)",
            // border: "2px solid blue",
            fontSize: "16px"
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.myTheme[4],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={AllTags} columns={columns} />
        <ToastContainer />

      </Box>
    </Box>
  );
};

export default ChatTags;