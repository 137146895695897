import React, { useEffect, useState, useMemo } from 'react';
import { Box, Typography, useTheme, Button, TextField, IconButton, Tabs, Tab } from "@mui/material";
import axios from 'axios';
import { tokens } from "../../Theme";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';



const MedicalReport = ({
    setone,
    settwo,
    setthr,
    setoneVal,
    settwoVal,
    setthrVal,
    data
}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // const { data } = props;
    const onlyList = ['sugar_level', 'status', 'medications', 'measure_type',
'device_name', 'existing_medication_type', 'brand_name', 'shortacting_brandname', 'longacting_brandname', 'sugar_highalert', 'sugar_lowalert', 'bp_highalert', 'bp_lowalert']

    return (
        <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        gap='5rem'
        flexWrap = 'wrap'
        >
            {
                Object.keys(data).filter((dat) => onlyList.includes(dat)).map((key, value) => {
                    return(
                        // <Box 
                        // display='flex' 
                        // flexDirection='column'
                        // flex='1 1 150px'
                        // width='auto'
                        // minWidth='150px' 
                        // justifyContent='space-between'>
                        // <Typography sx={{
                        //     textAlign:'center',
                        //     margin:'1rem'
                        //     // lineHeigh:'70px !important',
                        // }} variant='h1'>{data?.[key] || 'N/A'} </Typography>
                        // <Typography sx={{
                        //     borderTop : '1px solid black',
                        //     textAlign:'center',
                        //     lineHeight:'35px'
                        //     }}>{key}</Typography>
                        // </Box>
                        <Box
                        display='flex'
                        gap='1rem'
                        padding="1rem"
                        // width='fit-content'
                        minwidth='250px'
                        flex='1 1 26%'
                        alignItems='center'
                        height="150px"
                        boxShadow= "0 0 5px #000000, 5px 5px 10px #00e4d0"
                        >

                            <ArrowRightIcon fontSize="large"/>
                            <Box
                            display='flex'
                            flexDirection='column'
                            gap='1rem'

                            >
                            <Typography sx={{
                            // lineHeigh:'70px !important',
                        }} variant='h1'>{data?.[key] || 'NA'}</Typography>
                        <Typography variant='h4' sx={{
                            }}>{key?.split("_")?.map(word => word.charAt(0).toUpperCase() + word.slice(1))?.join(" ")}</Typography>

                            </Box>
                        </Box>
                    );
                })
            }
            
        </Box>
    );
}

export default MedicalReport;
