import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Box, Typography, useTheme, Button, TextField, IconButton, Tabs, Tab } from "@mui/material";

const SugarLevelGraph = (props) => {


  const [series, setseries] = useState([]);
  const theme = useTheme();
  // console.log(theme)

    React.useEffect(() => {
        const seri = [
          {
            name: 'Pre',
            type: 'line',
            data: [],
          },
          {
            name: 'Post',
            type: 'line',
            data: [],
          },
          {
            name: 'Short Acting Insulin',
            type: 'column',
            data: [],
          },
          {
            name: 'Long Acting Insulin',
            type: 'column',
            data: [],
          },
          {
            name: 'Tablets',
            type: 'column',
            data: [],
          },
        ]

        const timeSeries = []


          
        props.data && Object.keys(props.data).map((point) => {
            // console.log(seri, ']]]]]')
            // console.log(props.data, '][[[[[')
            seri[0].data.push(props.data[point]['pre'])
            seri[1].data.push(props.data[point]['post'])
            seri[2].data.push(props.data[point]['short_acting_insulin'])
            seri[3].data.push(props.data[point]['long_acting_insulin'])
            seri[4].data.push(props.data[point]['tablets'])
            timeSeries.push(props.data[point]['datetime'])
        }) 

        setseries(seri);
        setoptions({...options, xaxis:{ categories : timeSeries}})

    }, [props]);

    React.useEffect(() => {
      setoptions({...options, tooltip : {
        theme:theme.palette.mode
      }})
    }, [theme.palette.mode]);


   const [options, setoptions] =  useState({
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        toolbar: {
          show: false
        },
        zoom: {
          enabled: true
        },
        pan: {
          enabled: true
        }
      },
      tooltip:{
        followCursor: true,
        // fillSeriesColor:true,
        theme:theme.palette.mode
      },
      stroke: {
        // width: [0, 2, 5],
        // curve: 'smooth',
      },
      markers:{
        size: 6,
        hover: {
          size: 8,
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
        },
      },
      // colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560'],
      
      fill: {
        // opacity: [1, 1, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: 'vertical',
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [100, 100, 100, 100],
        },
      },
      // theme:theme,
      // labels: [],
      // markers: {
      //   size: 0,
      //   style: 'hollow',
      // },
      xaxis: {
        categories: [],
        title: {
          text: 'Dates',

          style: {
            color: '#FF4560',
          },
        },
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#FF4560',
        },
        labels: {
          style: {
            colors: '#FF4560',
          },
        },
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#FF4560',
          },
          labels: {
            style: {
              colors: '#FF4560',
            },
          },
          title: {
            text: 'Suger Level (mg/dL)',
            style: {
              color: '#FF4560',
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        // {
        //   seriesName: 'Pre',
        //   opposite: true,
        //   axisTicks: {
        //     show: true,
        //   },
        //   axisBorder: {
        //     show: true,
        //     color: '#00E396',
        //   },
        //   labels: {
        //     style: {
        //       colors: '#00E396',
        //     },
        //   },
        //   title: {
        //     text: 'Sugar Level (mg/dL)',
        //     style: {
        //       color: '#00E396',
        //     },
        //   },
        // },
        // {
        //   seriesName: 'Post',
        //   opposite: true,
        //   axisTicks: {
        //     show: true,
        //   },
        //   axisBorder: {
        //     show: true,
        //     color: '#FEB019',
        //   },
        //   labels: {
        //     style: {
        //       colors: '#FEB019',
        //     },
        //   },
        //   title: {
        //     text: 'Sugar Level (mg/dL)',
        //     style: {
        //       color: '#FEB019',
        //     },
        //   },
        // },
      ],
    })
  // });

  
  return (
    <div>
      <ReactApexChart options={options} series={series} type="line" height={350} />
      {/* <button onClick={changeChartData}>Change Data</button> */}
    </div>
  );
};

export default SugarLevelGraph;
