import React, {useState, useEffect} from 'react';
import { Box, Typography, useTheme, FormControlLabel, FormGroup, Checkbox, InputLabel, Select } from "@mui/material";
import Grid from '@mui/material/Grid';
import { tokens } from "../../Theme";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from "@mui/material/Divider";
import { withStyles, MenuItem } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';



const AddRoles = (props) => {
    
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [role, setRole] = useState("");
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    {props.isdata && setRole(props.data.role)}
    {props.isdata && setPermissions(props.data.permisions)}    
    {!props.isdata && setRole("") }
    {!props.isdata && setPermissions({
        "Roles":false,
        "Create Roles":false,
        "Edit Roles":false,
        "Delete Roles":false,
        "Tags":false,
        "Create Tags":false,
        "Edit Tags":false,
        "Delete Tags":false,
        "Operator":false,
        "Create Operator":false,
        "Edit Operator":false,
        "Delete Operator":false,
        "Patient":false,
        "Delete Patient":false,
        "Doctor":false,
        "Delete Doctor":false,
        "Setting":false,
      });
    }
  }, [props.isopen]);


    const handleAddRole = async () =>{
      const data = {
        'role' : role,
        'permisions' : permissions
      }
  
      var config = {
        method: 'post',
        url: 'https://vinecork.com/sugarX/api/add-privilages/',
        headers: { },
        data : data
      };
      
      await axios(config)
      .then(function (response) {
        props.set();
        toast.success(
          response?.data?.msg,
          {
            position: toast.POSITION.TOP_RIGHT
        }
        );
      })
      .catch(function (error) {
        toast.error(
          error?.response?.data?.msg,
          {
            position: toast.POSITION.TOP_RIGHT
        }
        );
      });
      

    }

    
    const handleEditRole = async () =>{
      
      const data = {
      'id' : props.data.id,
      'role' : role,
      'permisions' : permissions
    }

    var config = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/update-role/',
      headers: { },
      data : data
    };
    
    await axios(config)
    .then(function (response) {
      props.set();
      toast.success(
        response?.data?.msg,
        {
          position: toast.POSITION.TOP_RIGHT
      }
      );
    })
    .catch(function (error) {
      toast.error(
        error?.response?.data?.msg,
        {
          position: toast.POSITION.TOP_RIGHT
      }
      );
    });

    }

    
  const handlePermissionChange = (event) => {
     setPermissions({
        ...permissions,
        [event.target.name]: event.target.checked,
      })
  };

    return( 
    <Dialog
        fullWidth
        maxWidth="sm"
        open={props.isopen}
        // sx={{backgroundColor:colors.primary[500]}}
        onClose={() => {props.set()}}
        // disableEscapeKeyDown={true}
        // disableBackdropClick ={true}
      >
        <DialogContent         
        sx={{backgroundColor:colors.myTheme[1]}}>
          <Grid container >
            {/* <Grid item xs={8}> */}
              <Grid container direction="row" >
                <Grid item xs={8}>
                  <Typography color={colors.greenAccent[400]}  variant="h5">
                    Add Roles
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    color="secondary"
                    label="Role"
                    id="role"
                    name="role"
                    value={role}
                    onChange={(e) => {setRole(e.target.value);}}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography color={colors.greenAccent[400]}  variant="h5">
                    Permissions
                  </Typography>
                </Grid>
                
                  {
                  Object.keys(permissions).map((key) => {
                    return(
                      
                    <Grid item key={key}>
                      <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox checked={permissions[key]} onChange={handlePermissionChange} name={key} />
                              }
                              label={key}
                            />
                      </FormGroup>
                    </Grid>
                    );

                  })
                }
                
            </Grid>
        </Grid>
        </DialogContent>
        
        <DialogActions sx={{
          backgroundColor:colors.myTheme[1]}}>
                {
                    props.isdata 
                    ? <Button variant="outlined" color="info" onClick={handleEditRole}>Edit</Button>
                    : <Button variant="outlined" color="info" onClick={handleAddRole}>Add</Button>
                }
                <Button variant="outlined" color="error" onClick={() => {props.set()}}>Cancel</Button>
            </DialogActions>
            <ToastContainer />

    </Dialog>

    );

}

export default AddRoles;

   