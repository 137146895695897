import React, {useEffect, useState} from "react";
import { Box, Typography, useTheme, Button, Grid } from "@mui/material";
import { tokens } from "../../Theme";
import Rings from "./Rings";


const Board = ({title, score, color}) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
            <Box
            display='flex'
            flexDirection='column'
            // justifyContent="center"
            padding="1rem"
            alignItems="center"
            >
                <Rings score={score} color={color}/>
                <Typography variant="h3">
                    {title}
                </Typography>
            </Box>
    );
}

export default Board;
