import React, { useEffect, useRef, useState } from "react";
import { Routes, Route } from "react-router-dom";
// import Sidebar from "./pages/Global/Sidebar";
// import Navbar from "./pages/Global/Navbar";
import Dashboard from "./pages/Dashboard";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./Theme";
import Operator from "./pages/Operator";
import Doctor from "./pages/Doctor";
import Patient from "./pages/Patient";
import Terms from "./components/Terms";
import Roles from "./pages/Roles";
import Login from "./components/Authentication/login";
import { AuthProvider } from "./components/Authentication/AuthProvider";
import RequireAuth from "./components/Authentication/RequireAuth";
import PageBody from "./pages/Global/PageBody";
import PatientReport from "./components/Patients/PatientReport";
import ChatTags from "./pages/ChatTags";
import DoctorDetails from "./components/Doctors/DoctorDetails";


function App() {
  const [theme, colorMode] = useMode();
  // const [isSidebar, setIsSidebar] = useState(true);

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
              <Routes>

                <Route path="/login" element={<Login />} />

                <Route element={<RequireAuth />}>
                    <Route path="/" element={
                      <PageBody>
                        <Dashboard />
                      </PageBody>
                    } />

                    <Route path="/operator" element={
                      <PageBody>
                        <Operator />
                      </PageBody>
                    } />
                    <Route path="/patient" element={
                      <PageBody>
                        <Patient />
                      </PageBody>
                    } />
                    <Route path="/tags" element={
                      <PageBody>
                        <ChatTags />
                      </PageBody>
                    } />
                    <Route path="/doctor" element={
                      <PageBody>
                        <Doctor />
                      </PageBody>
                    } />
                    
                    
                    <Route path="/doctor/:did/:uid" element={
                      <PageBody>
                        <DoctorDetails />
                       </PageBody>
                    } />
                    
                    <Route path="/patient/:pid/:uid" element={
                      <PageBody>
                        <PatientReport />
                       </PageBody>
                    } />
                    <Route path="/terms" element={
                      <PageBody>
                        <Terms />
                      </PageBody>} />
                    <Route path="/role" element={
                      <PageBody>
                        <Roles />
                      </PageBody>} />
                  </Route>
              </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}

export default App;
