import React, {useEffect, useState} from "react";
import { Box, Typography, useTheme, Button, Grid } from "@mui/material";
import { tokens } from "../Theme";
import Rings from '../components/Dashboard/Rings';
import Board from "../components/Dashboard/Board";
import Header from "../components/Headline";
import CriticalTable from "../components/Dashboard/CriticalTable";
import axios from "axios";



const Dashboard = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [useCount, setuseCount] = useState({
    'doctor' : 0,
    'patient' : 0,
    'operator' : 0,
  });

  const fetchCount = async () => {
    var configOps = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/get-counts/',
      headers: { }
    };
    await axios(configOps)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      setuseCount(response.data.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  useEffect(() => {
    fetchCount();
  }, []);

    return (
        <Box m="20px">
            <Header title="DASHBOARD" subtitle="View & Manage Patients" />
            <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            // gridAutoRows="140px"
            gap="20px"
            >
                <Box gridColumn="span 4"
                backgroundColor={colors.myTheme[4]}
                display='flex'
                alignItems='center'
                justifyContent='center'
                >
                    {/* <Board title="DOCTOR" score={68} color={colors.blueAccent[400]}/> */}
                    <Board title="DOCTOR" score={useCount['doctor']} color="#00e4d0"/>
                </Box>
                
                <Box gridColumn="span 4"
                backgroundColor={colors.myTheme[4]}
                display='flex'
                alignItems='center'
                justifyContent='center'
                >
                    {/* <Board title="PATIENT" score={81} color={colors.greenAccent[400]}/> */}
                    <Board title="PATIENT" score={useCount['patient']} color="#00e4d0"/>
                </Box>
                
                <Box gridColumn="span 4"
                backgroundColor={colors.myTheme[4]}
                display='flex'
                alignItems='center'
                justifyContent='center'
                >
                    {/* <Board title="OPERATOR" score={38} color={colors.redAccent[400]}/> */}
                    <Board title="OPERATOR" score={useCount['operator']} color="#00e4d0"/>
                </Box>

            </Box>
            <Box sx={{
                margin:'40px 0'
            }}>
                <Box m="20px" mt="40px">

                <Typography
                    variant="h3"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "0 0 5px 0" }}
                >
                    MONTHLY CRITICAL PATIENT

                </Typography>
                </Box>
                <CriticalTable />
            </Box>
        </Box>
    );
}

export default Dashboard;
