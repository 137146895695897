import { Box, Typography, useTheme, Button } from "@mui/material";
import React, {useEffect, useState} from "react";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { tokens } from "../Theme";
import Avatar from '@mui/material/Avatar';
import { mockRoles } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Headline";
import RolesModal from "../components/Role/RolesModal";
import axios from 'axios';
import DeleteRoles from "../components/Role/DeleteRoles";

const Roles = () => {

  const [open, setOpen] = React.useState(false);
  const [AllRoles, setAllRoles] = useState({});
  const [ deleteopen, setDeleteOpen] = useState(false);
  const [isdata, setIsData] = useState(false);

  const [roleDetail, setRoleDetail] = useState({})
  const [permisions, setpermisions] = useState([]);

  useEffect(() => {
    setpermisions(JSON.parse(sessionStorage.getItem('permission')));
  }, [sessionStorage.getItem('permission')]);




  const handleEdit = (e, cellValues) => {
      setRoleDetail(cellValues.row);
      console.log(cellValues);
      setIsData(true);
      setOpen(true);
  }

  const handleAdd = (e) => {
      setRoleDetail({});
      setIsData(false);
      setOpen(true)
  }

  const handleDelete = (e, cellValues) => {
    setRoleDetail(cellValues.row);
    setDeleteOpen(true);
  }

  const handlePermissions = (e, cellValues) => {

  }

  
  const OpenAddRole = (action) => {
    setOpen(action);
  }

  useEffect(() => {
    var config = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/get-roles/',
      headers: { }
    };
    
    axios(config)
    .then(function (response) {
      setAllRoles(response.data.data)
    })
    .catch(function (error) {
      setAllRoles(error.response.data.data);
    });

  }, [open, deleteopen]);


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id",
     headerName: "ID",
    //  width:100
    }, 
    // {
    //     field: "pic",
    //     headerName: "Profile",
    //     // flex: 1,
    //     renderCell: ({ row: { pic } }) => {
    //       return (
    //         // <Box display="flex" justifyContent="center" alignItems="center">
    //         <Avatar alt={pic} src={pic} sx={{ width: 24, height: 24 }}/>
    //         // </Box>
    //       );
    //     },
    //   },
    {
      field: "role",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    
    
    {
        field: "edit",
        // type: 'actions',
        headerName: "Edit",
        renderCell: (cellValues) => {
          return (
            <Button
              variant="outlined"
              color="info"
              onClick={(event) => {
                handleEdit(event, cellValues);
              }}
              disabled={!permisions?.['Edit Roles']}
            >
              Edit
            </Button>
          );
        }
      },
    

    {
      field: "permission",
      headerName: "Permissions",
      // flex:1,
      
      renderCell: (cellValues) => {
        return (
          <Button
            variant="outlined"
            color="success"
            onClick={(event) => {
              handlePermissions(event, cellValues);
            }}
          >
            Permissions
          </Button>
        );
      }
    },
    

    {
        field: "delete",
        type: 'actions',
        headerName: "Delete",
        // flex:1,
        
        renderCell: (cellValues) => {
          return (
            <Button
              variant="outlined"
              color="error"
              onClick={(event) => {
                handleDelete(event, cellValues);
              }}
              disabled={!permisions?.['Delete Roles']}

            >
              Delete
            </Button>
          );
        }
      },
  ];

  return (
    <Box m="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
         <Header title="ROLES" subtitle="Manage Roles of the Operators" />
          <Button
              sx={{
                display:"flex",
                gap:"1rem",
                justifyContent:"space-between",
                borderRadius:"25px",
                border:"2px solid " + colors.myTheme[6],
              }} 
              onClick={handleAdd}
              disabled={!permisions?.['Create Roles']}

               variant="outlined" color="secondary">
                <PersonAddRoundedIcon fontSize="large" sx={{color: colors.myTheme[5],
                borderRadius: "25px",
                }}  />
                <Typography variant="h5" color={colors.myTheme[5]}>
                    ROLES
                </Typography>
          </Button>
          <RolesModal isopen={open} set={() => setOpen(!open)} data={roleDetail} isdata={isdata}/>
          <DeleteRoles isopen={deleteopen} set={() => setDeleteOpen(!deleteopen)} data={roleDetail} />

      </Box>
      <Box
        // m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "1px solid black",
            outline: "none !important",
            fontSize: "14px"
          },
          
          "& .name-column--cell": {
            color: colors.myTheme[3],
          },
          "& .MuiDataGrid-columnHeaders": {
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)",
            // border: "2px solid blue",
            fontSize: "16px"
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.myTheme[4],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={ AllRoles } columns={columns} />
      </Box>
    </Box>
  );
};

export default Roles;