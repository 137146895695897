import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import { tokens } from "../Theme";
import { mockOperator } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Headline";
import React, {useState, useEffect} from "react";
import axios from "axios";
import OperatorModal from "../components/Operator/OperatorModal";
import DeleteOperator from "../components/Operator/DeleteOperator";
import { ToastContainer, toast } from 'react-toastify';


const Operator = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = React.useState(false);
  const [ deleteopen, setDeleteOpen] = useState(false);
  const [isdata, setIsData] = useState(false);
  const [operatorDetail, setOperatorDetail] = useState({});
  const [permisions, setpermisions] = useState([]);

  useEffect(() => {
    setpermisions(JSON.parse(sessionStorage.getItem('permission')));
  }, [sessionStorage.getItem('permission')]);


  const handleOperatorEdit = (e, cellValues) => {
      setOperatorDetail(cellValues.row);
      setIsData(true);
      setOpen(true);
  }
  
  const handleOperatorAdd = (e) => {
      setOperatorDetail({});
      setOpen(true);
      setIsData(false);
  }


  const handleDelete = (e, cellValues) => {
    setOperatorDetail(cellValues.row);
    setDeleteOpen(true);
  }

  const [AllOperator, setAll] = useState({});
  const [AllRole, setAllRole] = useState({});

  const fetchOperators = async () => {
    var configOps = {
      method: 'get',
      url: 'https://vinecork.com/sugarX/api/get-operators/',
      headers: { }
    };
    await axios(configOps)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      setAll(response.data.data);
    })
    .catch(function (error) {
      console.log(error);
      setAll(error.response.data.data);
    });
  }

  // console.log(AllOperator)

  const fetchRoles = async () => {
    var configRole = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/get-roles/',
      headers: { },
    };

    await axios(configRole).then(function (response) {
          setAllRole(response.data.data);
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.msg,
            {
              position: toast.POSITION.TOP_RIGHT
          }
          );
          console.log(error);
        });


  }

  useEffect(() => {
    let mounted = true;
    if(mounted){
      fetchOperators();
      fetchRoles();
    }
    return () => {
      mounted = false;
    };
  }, [open, deleteopen]);



  const columns = [
    { 
      field: "id",
     headerName: "ID",
    //  width:100
    },
    {
      field: "username",
      headerName: "Userame",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "contact",
      headerName: "Contact",
    //   width:110
      // flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      // flex:1
      width:200
    },
    {
      field: "role",
      headerName: "Role",
      width:200,
      // flex: 1,
      renderCell: ({ row: { role } }) => {

        const getMyrole = () => {
          try {
            var myrole = [...AllRole].find(roles => roles.id === role).role
            return myrole
          }catch{
            return "NotFound"
          }
        }

        // console.log(AllRole)
        const myRole = getMyrole();
        // console.log(myRole)

        return (
        <Button
          variant="outlined"
          color={
            myRole === "Admin"
            ? "error"
            :myRole === "Developer"
            ? "warning"
            :myRole === "User"
            ? "secondary"
            : "primary"
          }
          sx={{
            borderRadius:"25px",
            display:"flex",
            alignItems:"center",
            gap:"0.5rem"
            
          }}
            >
              {myRole === 'Admin' && <AdminPanelSettingsOutlinedIcon />}
             {myRole === 'Developer' && <SecurityOutlinedIcon />}
             {myRole === "User" && <LockOpenOutlinedIcon />}
              { myRole}
            </Button>
        );
      },
    },
    
    
    
      {
        field: "edit",
        // type: 'actions',
        headerName: "Edit",
        renderCell: (cellValues) => {
          return (
            <Button
              variant="outlined"
              color="info"
              onClick={(event) => {
                // handleEdit(event, cellValues);
                handleOperatorEdit(event, cellValues);
              }}
              disabled={!permisions?.['Edit Operator']}
            >
              Edit
            </Button>
          );
        }
      },

      {
        field: "delete",
        headerName: "Delete",
        // flex:1,
        
        renderCell: (cellValues) => {
          return (
            <Button
              variant="outlined"
              color="error"
              onClick={(event) => {
                handleDelete(event, cellValues);
              }}
              disabled={!permisions?.['Delete Operator']}
            >
              Delete
            </Button>
          );
        }
      }
  ];

  return (
    <Box m="20px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
      <Header title="OPERATOR" subtitle="The one who Operates" />
          <Button
              sx={{
                display:"flex",
                gap:"1rem",
                justifyContent:"space-between",
                borderRadius:"25px",
                border:"2px solid " + colors.myTheme[6],
              }} 
              disabled={!permisions?.['Create Operator']}
              onClick={handleOperatorAdd}
              variant="outlined" color="secondary">
                <PersonAddRoundedIcon fontSize="large" sx={{color: colors.myTheme[5],
                borderRadius: "25px",
                }}  />
                <Typography variant="h5" color={colors.myTheme[5]}>
                    OPERATOR
                </Typography>
          </Button>
          {/* <AddOperator open={open} set={() => handlePatientAdd(false)}/> */}
      </Box>
      {/* <EditOperator open={Editopen} set={() => handleEditClose()} data={EditDetails}/> */}
      <OperatorModal isopen={open} set={() => setOpen(!open)} data={operatorDetail} isdata={isdata} AllRole={AllRole}/>
      <DeleteOperator isopen={deleteopen} set={() => setDeleteOpen(!deleteopen)} data={operatorDetail} />
      <Box
        // m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "1px solid black",
            fontSize: "14px"
          },
          "& .name-column--cell": {
            color: colors.myTheme[3],
          },
          "& .MuiDataGrid-columnHeaders": {
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)",
            // border: "2px solid blue",
            fontSize: "16px"
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.myTheme[4],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={AllOperator} columns={columns} />
        <ToastContainer />

      </Box>
    </Box>
  );
};

export default Operator;