import React from 'react';
import "../../assets/Dashboard/Rings.css";

const Rings = ({score, color}) => {
    return (
        <div className="ScoreContainer">
        <div className="Score" style={{
    ['--clr'] :  color,
    ['--num'] :  score,
}}>
            <div className="dot"></div>
            <svg>
                <circle cx="80" cy="80" r="80"></circle>
                <circle cx="80" cy="80" r="80"></circle>
            </svg>
            <div className="number">
                <h2>{score}</h2>
            </div>
        </div>
    </div>
    );
}

export default Rings;
