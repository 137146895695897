import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";

const RequireAuth = () => {
    
    const { auth } = useAuth();
    const location = useLocation();
    const user = JSON.parse(sessionStorage .getItem('user'));
    // console.log(user)
    return(
        auth?.email || user?.data?.email
        ? <Outlet />
        : <Navigate to='/login' state={{ from: location}} replace />
    )

}

export default RequireAuth;