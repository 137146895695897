import React from 'react';
import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu} from 'react-pro-sidebar';
import { ToastContainer, toast } from 'react-toastify';
import { ColorModeContext, tokens } from "../../Theme";
import pic from "../../assets/Images/1.jpg";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from "@mui/icons-material/Search";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import useAuth from '../../components/Authentication/useAuth';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';


const Navbar = () => {
    
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { collapseSidebar, toggleSidebar } = useProSidebar();
  const {auth, setAuth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();


  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleLogout = () => {
    sessionStorage.clear()
    navigate('/login')
    
  }

 

    return (
    <Box display="flex" justifyContent="space-between"  sx={{ background: "linear-gradient(120deg, #00e4d0, #5983e8)" }} p={2}>
      {/* SEARCH BAR */}
      <Box
      display="flex"
      gap="1rem">

        <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                
                // ml="15px"
              >
                <IconButton onClick={() => {
                  collapseSidebar();
                  }}>
                  <MenuOutlinedIcon />
                </IconButton>
        </Box>

      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton onClick={()=>collapseSidebar()} type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box>

      </Box>

      {/* ICONS */}
      <ToastContainer />

      <Box display="flex">
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton> */}
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}
        <div>
          
          {/* <IconButton >
            <PersonOutlinedIcon />
          </IconButton> */}
          <Button aria-describedby={id} onClick={handleClick}>
            <IconButton>
              <SettingsOutlinedIcon />
            </IconButton>
          </Button>
          
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box
            display='grid'
            // gap='1rem'
            // width="120px"
            // height='100px'
            padding='1rem'
            marginRight='1rem'
            >
              <Button 
              // variant='outlined'
              color='info'
              sx={{
                boderRadius:'12px'
              }}
              >
                  <IconButton >
                    <PersonOutlinedIcon />
                  </IconButton>
                  Profile
              </Button>
              <Button
              // variant='outlined'
              onClick={handleLogout}
              color='error'
              sx={{
                boderRadius:'12px'
              }}>
                  <IconButton >
                    <LogoutIcon />
                  </IconButton>
                  Logout
              </Button>
            </Box>
          </Popover>
        </div>
        
      </Box>

    </Box>
  );
}

export default Navbar;
