import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import React, {useEffect, useState} from "react";
import { pink, blue } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { tokens } from "../../Theme";
import Avatar from '@mui/material/Avatar';
// import { mockPatient } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import FemaleIcon from '@mui/icons-material/Female';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import MaleIcon from '@mui/icons-material/Male';
// import Header from "../components/Headline";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";

const CriticalPatient = ({AllPatients}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [permisions, setpermisions] = useState([]);

  useEffect(() => {
    setpermisions(JSON.parse(sessionStorage.getItem('permission')));
  }, [sessionStorage.getItem('permission')]);



  

  

  const columns = [
    { field: "id",
     headerName: "User Id",
    //  width:100
    }, {
        field: "profile_pic",
        headerName: "Profile",
        // flex: 1,
        renderCell: ({ row: { profile_pic } }) => {
            return (
              <Avatar alt={profile_pic}                     
              src={profile_pic ? `https://vinecork.com/sugarX/api/${profile_pic}` : null}
              sx={{ width: 36, height: 36 }}/>
            );
          },
      },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      minWidth: 160
    },
    {
      field: "dob",
      headerName: "DOB",
      type: "date",
      headerAlign: "left",
      align: "left",
      hide: false
    },
    
    {
        field: "gender",
        headerName: "Gender",
        headerAlign: "left",
        align: "center",
        hide: false,
        renderCell: ({row: { gender }}) => {
          return (
            <Box>
              {
                gender === "Female"
                ? <FemaleIcon sx={{
                  color:pink[500]
                }}/>
                : gender === "Male"
                ? <MaleIcon sx={{
                  color:blue[500]
                }}/>
                : <NoAccountsIcon />
              }

            </Box>
          );
        }
      },

    

      {
        field: "phone",
        headerName: "Phone",
        headerAlign: "left",
        align: "left",
      },
      
    
    {
        field: "type_of_dibeties",
        headerName: "Dibeties",
        headerAlign: "left",
        align: "left",
        hide: false,
      },
    {
        field: "info",
        // type: 'actions',
        headerName: "Details",
        hide: false,
        renderCell: ({row}) => {
          return (
            <Button
              variant="outlined"
              color="info"
              // onClick={(event) => {
              //   openDetails(event, user_id);
              // }}
            >
              <Link to={`/patient/${row.id}/${row.user_id}`} style={{
                textDecoration:'none',
                color:'#0288d1'
              }}>Report</Link>
            </Button>
            // <Link to='/patient/report'>Details</Link>
          );
        }
      },
  ];

  return (
    <Box>
      <Box
        // m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            // borderBottom: "1px solid black",
            fontSize: "14px"
          },
          "& .name-column--cell": {
            color: colors.myTheme[3],
            
          },
          "& .MuiDataGrid-columnHeaders": {
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)",
            // border: "2px solid blue",
            fontSize: "16px"
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.myTheme[4],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            // backgroundColor: colors.primary[400],
            background: "linear-gradient(120deg, #00e4d0, #5983e8)"
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={ AllPatients } columns={columns} />
      </Box>
    </Box>
  );
};

export default CriticalPatient;