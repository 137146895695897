import { useState, useEffect } from "react";
// import { ProSidebar, Menu, MenuItem, SubMenu, SidebarFooter, SidebarHeader, SidebarContent  } from "react-pro-sidebar";
import { Sidebar, Menu, MenuItem, useProSidebar, SubMenu} from 'react-pro-sidebar';

import { Box, IconButton, Typography, useTheme } from "@mui/material";
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import CallIcon from '@mui/icons-material/Call';
import { Link } from "react-router-dom";
// import "react-pro-sidebar/dist/css/styles.css";
import pic from "../../assets/Images/1.jpg";
import { tokens } from "../../Theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MedicationIcon from '@mui/icons-material/Medication';
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      rootStyles={{
        "& .ps-menu-button":{
          background:"none"
        },
        "& .ps-menu-button:hover":{
          background:"none"
        },
        "& .ps-active":{
          background:"none !important"
        },
        color: colors.grey[100],
        margin:"0.1rem 0",
        ':active':{
          color: colors.myTheme[6]+" !important"
        },
        ':hover':{
          color: colors.myTheme[6]+" !important"
        },
      }}
      onClick={() => setSelected(title)}
      routerLink={<Link to={to} />}
      icon={icon}
    >
      <Typography variant="h5">{title}</Typography>
    </MenuItem>
  );
};

const SideNav = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { collapseSidebar, toggleSidebar, collapsed } = useProSidebar();
  const [selected, setSelected] = useState("Dashboard");
  const [permisions, setpermisions] = useState([]);

  useEffect(() => {
    setpermisions(JSON.parse(sessionStorage.getItem('permission')));
  }, [sessionStorage.getItem('permission')]);

  return (
    <Box
      sx={{
        // display:"flex",
        // alignItems:"flex-start"
      }}
      // sx={{
      //   "& .pro-sidebar-inner": {
      //     background: `${colors.myTheme[4]} !important`,
      //   },
      //   "& .pro-icon-wrapper": {
      //     backgroundColor: "transparent !important",
      //   },
      //   "& .pro-inner-item": {
      //     padding: "5px 35px 5px 20px !important",
      //     justifyContent: "space-between",
      //   },
      //   "& .pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item":{
      //       justifyContent: "space-between",

      //   },
      //   "& .pro-inner-item:hover": {
      //     color: colors.myTheme[6]+" !important",
      //   },
      //   "& .pro-menu-item.active": {
      //     color: colors.myTheme[6]+" !important",
      //   },
      // }}
    >

    <Sidebar
    rootStyles={{
      "& .ps-sidebar-container": {
            height:"100vh",
            background: `${colors.myTheme[4]} !important`,
          },
      // background: `${colors.myTheme[4]} !important`,
    }}>
        <Menu>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                ml="15px"
                margin= "20px 0 20px 0"
                color= {colors.grey[100]}
              textAlign= 'center'

              >{
                collapsed
                ?<Typography variant="h5" color={colors.grey[100]}>
                  SugarX
                </Typography>
                :
                <Typography variant="h2" color={colors.grey[100]}>
                  SugarX
                </Typography>
              }
              </Box>
            
          <Box>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <SubMenu 
            rootStyles={{
              "& .ps-submenu-content":{
                background: `${colors.myTheme[4]} !important`
              },
              "& .ps-menu-button":{
                background: "none !important"
              },
              color: colors.grey[100],
              fontSize:"16px",
              margin:"0.1rem 0",
              ':active':{
                color: colors.myTheme[6]+" !important"
              },
              ':hover':{
                color: colors.myTheme[6]+" !important"
              },
            }}
            label="Manage" icon={<ManageAccountsIcon />}>
                {permisions?.['Roles'] && <Item
                title="Role"
                to="/role"
                icon={<ManageAccountsIcon />}
                selected={selected}
                setSelected={setSelected}
                />}
                {permisions?.['Tags'] && <Item
                title="Tags"
                to="/tags"
                icon={<ManageAccountsIcon />}
                selected={selected}
                setSelected={setSelected}
                />}
            </SubMenu>


            {permisions?.['Operator'] && <Item
              title="Operator"
              to="/operator"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />}
            {permisions?.['Patient'] && <Item
              title="Patient"
              to="/patient"
              icon={<WheelchairPickupIcon />}
              selected={selected}
              setSelected={setSelected}
            />}
            {permisions?.['Doctor'] && <Item
              title="Doctor"
              to="/doctor"
              icon={<MedicationIcon />}
              selected={selected}
              setSelected={setSelected}
            />}
            {permisions?.['Setting'] && <SubMenu 
            rootStyles={{
              "& .ps-submenu-content":{
                background: `${colors.myTheme[4]} !important`
              },
              "& .ps-menu-button":{
                background: "none !important"
              },
              color: colors.grey[100],
              fontSize:"16px",
              margin:"0.1rem 0",
              ':active':{
                color: colors.myTheme[6]+" !important"
              },
              ':hover':{
                color: colors.myTheme[6]+" !important"
              },
            }} label="Setting" icon={<SettingsIcon />}>
                
                <Item
                    title="Terms & Condition"
                    to="/terms"
                    icon={<GavelIcon />}
                    selected={selected}
                    setSelected={setSelected}
                />
                <Item
                    title="Privacy Policy"
                    to="/privacy"
                    icon={<SecurityIcon />}
                    selected={selected}
                    setSelected={setSelected}
                />
                <Item
                    title="Contact"
                    to="/contact"
                    icon={<CallIcon />}
                    selected={selected}
                    setSelected={setSelected}
                />

            </SubMenu>}
          </Box>
        </Menu>
      </Sidebar>
      
    </Box>
  );
};

export default SideNav;