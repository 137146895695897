import React, {useState, useEffect} from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import Grid from '@mui/material/Grid';
import { tokens } from "../../Theme";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from "@mui/material/Divider";
import { withStyles, MenuItem } from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import Switch from '@mui/material/Switch';


const TagsModal = (props) => {


    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [TagsDetail, setTagsDetail] = useState({
        tag_name:"",
        status:false
    });
    
    
      
    const handleClose = () => {
        props.set();
    };

    useEffect(() => {
        {
            props.isdata ? 
                setTagsDetail({
                    id : props.data.id,
                    tag_name : props.data.tag_name,
                    status : props.data.status
                }
                )
             :
                setTagsDetail({
                    tag_name : "",
                    status :false
                }
            )
        }
    }, [props.isopen]);    

  const handleAddTags = async () =>{
    var config = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/create-tags/',
      headers: { },
      data : TagsDetail,
    };
    
    await axios(config).then(function (response) {
      toast.success(
        response?.data?.msg,
        {
          position: toast.POSITION.TOP_RIGHT
      }
      );
      props.set();
    })
    .catch(function (error) {
      toast.error(
        error.response.data.msg.email,
        {
          position: toast.POSITION.TOP_RIGHT
      }
      );
      console.log(error);
    });
}

const handleEditTags = async () =>{
    var config = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/update-tags/',
      headers: { },
      data : TagsDetail,
    };
    
    await axios(config).then(function (response) {
      toast.success(
        response?.data?.msg,
        {
          position: toast.POSITION.TOP_RIGHT
      }
      );
      props.set();
    })
    .catch(function (error) {
      toast.error(
        error.response.data.msg.email,
        {
          position: toast.POSITION.TOP_RIGHT
      }
      );
      console.log(error);
    });
}



    return ( 
        <Dialog
            fullWidth
            maxWidth="sm"
            open={props.isopen}
          >
            <DialogContent         
            sx={{backgroundColor:colors.myTheme[1]}}>
              <Grid container >
                {/* <Grid item xs={8}> */}
                  <Grid container direction="row" >
                    <Grid item xs={8}>
                      <Typography color={colors.myTheme[6]}  variant="h5">
                        Add Chat Tags
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justifyContent='space-between'>
                    
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        margin="dense"
                        color="info"
                        variant="outlined"
                        label="Tag"
                        value={TagsDetail.tag_name}
                        name="tag_name"
                        required
                        multiline
                        maxRows={4}
                        onChange={(event) => {
                            setTagsDetail({
                                ...TagsDetail,
                                ['tag_name']: event.target.value
                            })
                        }}
                      />
                    </Grid>
                    
                    <Grid item xs={2}>
                      <Box>
                        <Typography>Status</Typography>
                        <Switch
                          checked={TagsDetail.status}
                          onChange={(event) => {
                            setTagsDetail({
                                ...TagsDetail,
                                ['status']: !TagsDetail.status
                            })
                        }}
                          inputProps={{ 'aria-label': 'controlled' }}
                          color='secondary'
                          />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
            </DialogContent>
            
            <DialogActions sx={{backgroundColor:colors.myTheme[1]}}>
                {
                    props.isdata 
                    ? <Button variant="outlined" color="info" onClick={handleEditTags}>Edit</Button>
                    : <Button variant="outlined" color="info" onClick={handleAddTags}>Add</Button>

                }
                <Button variant="outlined" color="error" onClick={handleClose}>Cancel</Button>
            </DialogActions>
            <ToastContainer />
        </Dialog>
    
        );
}

export default TagsModal;
