import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { Box, Typography, useTheme, Button, TextField, IconButton, Tabs, Tab } from "@mui/material";
import Profile from '../Profile/profile';
import { useLocation, useParams } from 'react-router-dom';
import Header from '../Headline';
import { pink, blue } from '@mui/material/colors';
import PersonalDetails from './PersonalDetails';
import MedicalReport from './MedicalReport';
import FoodDetails from './FoodDetails';
import BloodPressure from './BloodPressure';
import SugarLevel from './SugarLevel';



function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  
    
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const PatientReport = () => {

    const { pid, uid } = useParams();
    // console.log(pid, 'aaaaaaaaa')
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    

    const location = useLocation()
    // const  user_id  = location.state.patientDetail.id;
    const [personalDetail, setPersonalDetail] = useState({});
    const [medicalReport, setMedicalReport] = useState({});
    const [foodDetails, setFoodDetails] = useState({});
    const [sugarLevel, setSugarLevel] = useState({});

    const [circleone, setcircleone] = useState("HEIGHT");
    const [oneValue, setoneValue] = useState(0);
    const [circletwo, setcircletwo] = useState("WEIGHT");
    const [twoValue, settwoValue] = useState(0);
    const [circlethr, setcirclethr] = useState("AGE");
    const [thrValue, setthrValue] = useState(0);



    const getPersonalDetails = (pid) => {

      var config = {
      method: 'post',
      url: 'https://vinecork.com/sugarX/api/get-patient-details/',
      headers: { },
      data : {'id' : pid}
      };

   axios(config).then(function (response) {
        // console.log(JSON.stringify(response.data));
        setPersonalDetail(response.data.data);
      }).catch(function (error) {
        console.log(error);
      });
  }

  const getMedicalReport = (pid) => {

    var config = {
    method: 'post',
    url: 'https://vinecork.com/sugarX/api/get-medical-report/',
    headers: { },
    data : {'patient_id' : pid}
    };

 axios(config).then(function (response) {
      setMedicalReport(response.data.data);
    }).catch(function (error) {
      console.log(error);
    });
}

useEffect(() => {
  if(pid){
      getPersonalDetails(uid);
      getMedicalReport(pid);
  }
}, []);

// useEffect(() => {
//   value
// }, [])


    return (
        <Box 
        m="20px"
        maxHeight="85vh"
        overflow="auto"
        >
            <Header title={"Patient Detail"} subtitle={"Details of the patients"}/>
            <Profile 
              data={personalDetail} 
              one={circleone} 
              two={circletwo}
              thr={circlethr}
              oneVal={oneValue} 
              twoVal={twoValue}
              thrVal={thrValue}
            />
            <Box m={'20px'} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs 
                    value={value} 
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile>
                    <Tab label="Personal Details" {...a11yProps(0)} />
                    <Tab label="Medical Report" {...a11yProps(1)} />
                    {/* <Tab label="Food Details" {...a11yProps(2)} /> */}
                    <Tab label="Blood Pressure" {...a11yProps(2)} />
                    <Tab label="Sugar Level" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    { personalDetail 
                    ? <PersonalDetails 
                    data={ personalDetail }
                    setone={(v) => {setcircleone(v)}} 
                    settwo={(v) => {setcircletwo(v)}} 
                    setthr={(v) => {setcirclethr(v)}} 
                    setoneVal={(v) => {setoneValue(v)}}  
                    settwoVal={(v) => {settwoValue(v)}} 
                    setthrVal={(v) => {setthrValue(v)}} 
                    />
                    : <Typography>No Personal Details Present</Typography>
                    }
                </TabPanel>
                <TabPanel value={value} index={1}>
                    { medicalReport
                    ? <MedicalReport 
                    data={ medicalReport }
                    setone={(v) => {setcircleone(v)}} 
                    settwo={(v) => {setcircletwo(v)}} 
                    setthr={(v) => {setcirclethr(v)}} 
                    setoneVal={(v) => {setoneValue(v)}}  
                    settwoVal={(v) => {settwoValue(v)}} 
                    setthrVal={(v) => {setthrValue(v)}} 
                    />
                    : <Typography>No Mediacal Details Present</Typography>
                    }
                </TabPanel>
                {/* <TabPanel value={value} index={2}>
                    <FoodDetails data={ foodDetails }/>
                </TabPanel> */}
                <TabPanel value={value} index={2}>
                    <BloodPressure 
                    user_id={pid}
                    setone={(v) => {setcircleone(v)}} 
                    settwo={(v) => {setcircletwo(v)}} 
                    setthr={(v) => {setcirclethr(v)}} 
                    setoneVal={(v) => {setoneValue(v)}}  
                    settwoVal={(v) => {settwoValue(v)}} 
                    setthrVal={(v) => {setthrValue(v)}} 
                    />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <SugarLevel 
                    user_id={pid}
                    setone={(v) => {setcircleone(v)}} 
                    settwo={(v) => {setcircletwo(v)}} 
                    setthr={(v) => {setcirclethr(v)}} 
                    setoneVal={(v) => {setoneValue(v)}}  
                    settwoVal={(v) => {settwoValue(v)}} 
                    setthrVal={(v) => {setthrValue(v)}} 
                    />
                </TabPanel>
                </Box>
        </Box>
    );
}

export default PatientReport;
