import React, {useState, useEffect} from 'react';
import ReactApexChart from "react-apexcharts";
import { date } from 'yup';
import { Box, Typography, useTheme, Button, TextField, IconButton, Tabs, Tab } from "@mui/material";


const Line = (props) => {
    // console.log(props, 'eeeeeeeeeee')
    const theme = useTheme();

    const [series, setseries] = useState([]);

    useEffect(() => {
        const seri = [
            {
              name: "Systolic",
              data: []
            },
            {
              name: "Diastolic",
              data: []
            },
            {
              name: "Pulse",
              data: []
            }
          ]

        const timeSeries = []


          
        props.data && Object.keys(props.data).map((point) => {
            // console.log(seri, ']]]]]')
            // console.log(props.data, '][[[[[')
            seri[0].data.push(props.data[point]['systolic'])
            seri[1].data.push(props.data[point]['diastolic'])
            seri[2].data.push(props.data[point]['pulse'])
            timeSeries.push(props.data[point]['datetime'])
        }) 

        setseries(seri);
        setoption({...option, xaxis:{ categories : timeSeries}})

    }, [props]);



    React.useEffect(() => {
      setoption({...option, tooltip : {
        theme:theme.palette.mode
      }})
    }, [theme.palette.mode]);


    const [option, setoption] = useState({
        chart: {
          height: 350,
          type: 'line',
          // dropShadow: {
          //   enabled: true,
          //   color: '#000',
          //   top: 18,
          //   left: 7,
          //   blur: 10,
          //   opacity: 0.2
          // },
          toolbar: {
            show: false
          }
        },
      //   colors: ['#77B6EA', '#545454'],
        tooltip:{
          followCursor: true,
          // fillSeriesColor:true,
          theme:theme.palette.mode
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Patient Blood Pressure Report',
          align: 'left'
        },
        grid: {
          // borderColor: '#e7e7e7',
          // row: {
          //   colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          //   opacity: 0.5
          // },
        },
        markers: {
          size: 6
        },
        xaxis: {
          categories: [],
          title: {
            text: 'Dates',

            style: {
              color: '#FF4560',
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#FF4560',
          },
          labels: {
            style: {
              colors: '#FF4560',
            },
          },
        },
        yaxis: 
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: '#FF4560',
          },
          labels: {
            style: {
              colors: '#FF4560',
            },
          },
          title: {
            text: 'Measure',
            style: {
              color: '#FF4560',
            },
          },
          tooltip: {
            enabled: true,
            
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      },);

      


    return (
        <div id="chart">
            <ReactApexChart options={option} series={series} type="line" height={350} />
        </div>
    );
}

export default Line;
